export default {
    data() {
        return {
            scenes: [],
        }
    },
    mounted() {
        this.initScenes()
        setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 500)
    },
    methods: {
        initScenes() {
            this.scenesQuemSomos()
            this.scenesServicos()
        },
        scenesServicos() {
            //First
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#servicos .areas',
				triggerHook: 0.7,
                reverse: false
			}).setTween("#servicos ._01", {
				css: {
					opacity: '1',
				}
			}))

            //Second
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#servicos .areas ._02',
				triggerHook: 0.2,
                reverse: false
			}).setTween("#servicos .areas ._02", {
				css: {
					opacity: '1',
				}
			}))
            
            //Third
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#servicos .areas ._03',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#servicos .areas ._03", {
				css: {
					opacity: '1',
				}
			}))
        },
        scenesQuemSomos() {
			//Web
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#quemSomos',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#quemSomos .web", {
				css: {
					opacity: '1',
				}
			}))
			
            //Icons
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#quemSomos',
				triggerHook: 0.27,
                reverse: false
			}).setTween("#quemSomos .icons ._01", {
				css: {
					opacity: '1',
				}
			}))
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#quemSomos',
				triggerHook: 0.2,
                reverse: false
			}).setTween("#quemSomos .icons ._02", {
				css: {
					opacity: '1',
				}
			}))
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#quemSomos',
				triggerHook: 0.17,
                reverse: false
			}).setTween("#quemSomos .icons ._03", {
				css: {
					opacity: '1',
				}
			}))
			
            //Texts
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#quemSomos',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#quemSomos .text", {
				css: {
					opacity: '1',
                    transform: "translateY(0)"
				}
			}))
		},
    }
}