<template lang="pug">
    section#quemSomos
        SvgIcon(data="@svgs/servicos/servicos-quemsomos.svg", original).web
        .wrapper
            .text
                h3
                    | Pr
                    span._1100 o
                    | v
                    span._1100 o
                    | c
                    span._1100 a
                    | d
                    span._1100 o
                    | r
                    span._1100 e
                    | s, 
                    br
                    span._1100 i
                    | n
                    span._1100 o
                    | v
                    span._1100 a
                    | d
                    span._1900 o
                    | r
                    span._1100 e
                    | s, 
                    br
                    | c
                    span._1100 u
                    | r
                    span._1100 i
                    span._1900 o
                    | s
                    span._1900 o
                    | s 
                    br
                    span._1900 e
                    | &nbsp;cr
                    span._1100 ia
                    | t
                    span._1100 i
                    | v
                    span._1100 o
                    | s
                p.padding
                    |A Mind é uma consultoria de Design Estratégico com a missão de dar um impulso nas sinapses das pessoas envolvidas em cada projeto, provocando mudanças de dentro para fora das organizações e gerando inovações nos negócios.
                    br
                    br
                    |Nossa equipe é multidisciplinar e data-aware, que utiliza dados quantitativos e qualitativos para transformá-los em informações relevantes na tomada de decisão. 
                p
                    |Com foco no ser humano e no planeta, desenvolvemos relatórios de tendências, criamos novos negócios, produtos, serviços e marcas. Além disso, transformamos digitalmente os projetos e melhoramos seus processos.
                    br
                    br
                    strong Quer saber como? Vem conhecer nossos serviços:
            ul.icons
                li
                    SvgIcon(data="@svgs/icon_01.svg", original)._01
                li
                    SvgIcon(data="@svgs/icon_02.svg", original)._02
                li
                    SvgIcon(data="@svgs/icon_03.svg", original)._03
</template>

<script>
export default {
    name: "section-quem-somos",
}
</script>

<style lang="stylus" scoped src="./QuemSomos.styl"></style>