<template lang="pug">
    section#banner
        .wrapper
            p
                |Seu desafio no centro
                br
                |do nosso córtex através de
            transition(name="fade" mode="out-in")
                h2(v-html="titles[ativo]" :key="titles[ativo]")
            .svgs
                SvgIcon(data="@svgs/icon_03.svg", original, v-for="i, index in 3" :key="index")
        SvgIcon(data="@svgs/servicos/face.svg", original).face
</template>

<script>
export default {
    name: "section-banner",
    data() {
        return {
            titles: [
                "R<span class='_1100'>E</span>S<span class='_1100'>EA</span>RCH Y<span class='_1100'>OU</span>R <br> N<span class='_1100'>EU</span>R<span class='_1100'>A</span>L F<span class='_1100'>U</span>T<span class='_1100'>U</span>R<span class='_1100'>E</span>",
                "B<span class='_1100'>OO</span>S<span class='_1100'>T</span> YO<span class='_1100'>u</span>r<br> b<span class='_1100'>u</span><span class='_1300'>s</span>i<span class='_1100'>NE</span>ss & M<span class='_1100'>I</span>NDs<span class='_1900'>e</span>t",
                "r<span class='_1900'>e</span>M<span class='_1100'>I</span>ND & <span class='_1100'>I</span>mpr<span class='_1100'>o</span>v<span class='_1100'>e</span> <br> y<span class='_1100'>ou</span>r b<span class='_1100'>r</span>a<span class='_1100'>n</span>d",
            ],
            ativo: -1,
        }
    },
    mounted() {
        this.changeTitle()
        this.ativo = 0
    },
    methods: {
        changeTitle() {
            setInterval(() => {
                if(this.ativo >= this.titles.length - 1)
                    this.ativo = 0
                else
                    this.ativo += 1
            }, 5000)
        }
    }
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
