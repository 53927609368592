<template lang="pug">
    section#conecta
        .wrapper
            ul.svgs
                li(v-for="i in 4" ref="li")
                    SvgIcon(data="@svgs/icon_02.svg", original)
            h3
                | V
                span._1100 e
                | j
                span._1100 a
                | &nbsp;q
                span._1100 ue
                | m c
                span._1100 o
                | n
                span._1100 e
                | ct
                span._1100 a
                br
                span._1100 a
                |s s
                span._1100 i
                | n
                span._1100 a
                | ps
                span._1100 e
                | s c
                span._1100 o
                | m&nbsp;
                span._1100 a
                | &nbsp;g
                span._1100 e
                | nt
                span._1100 e

            ul.empresas
                li(v-for="empresa in empresas" ref="empresa")
                    img(:src="empresa.logo", alt="empresa.nome")
</template>

<script>
export default {
    name: "section-conecta",
    data() {
        return {
            scenes: [],
            empresas: [
                {
                    nome: 'Almha Participações',
                    logo: require('@images/logos/almha.png'),
                },
                {
                    nome: 'Faculdade Inspirar',
                    logo: require('@images/logos/inspirar.png'),
                },
                {
                    nome: 'Grupo Rejaile',
                    logo: require('@images/logos/rejaile.png'),
                },
                {
                    nome: 'Grupo Massa',
                    logo: require('@images/logos/massa.png'),
                },
                {
                    nome: 'Clínica Ortovitalli',
                    logo: require('@images/logos/ortovitalli.webp'),
                },
            ]
        }
    },
    mounted() {
        this.initScenes()
        setTimeout(() => {
            this.$scrollmagic.addScene(this.scenes)
        }, 500)
    },
    methods: {
        initScenes() {
            this.scenesClientes()
        },
		scenesClientes() {
			//Svgs
			this.$refs.li.forEach((item, index) => {
				this.scenes.push(this.$scrollmagic.scene({
					triggerElement: '#servicos #conecta',
					triggerHook: 0.7,
					reverse: false
				}).setTween(`#servicos #conecta .svgs li:nth-child(${index+1})`, {
					css: {
						opacity: "1",
					}
				}))
			});

			//Title
            this.scenes.push(this.$scrollmagic.scene({
                triggerElement: '#servicos #conecta',
                triggerHook: 0.6,
                reverse: false
            }).setTween(`#servicos #conecta h3`, {
                css: {
                    opacity: "1",
                    transform: 'translateY(0)'
                }
            }))

            //Empresas
			this.$refs.empresa.forEach((item, index) => {
				this.scenes.push(this.$scrollmagic.scene({
					triggerElement: '#servicos #conecta',
					triggerHook: 0.5,
					reverse: false
				}).setTween(`#servicos #conecta .empresas li:nth-child(${index+1})`, {
					css: {
						animation: 'boxAnimation 1s linear forwards ' + (0.5*index+'s'),
					}
				}))
			});
		},
    }
}
</script>

<style lang="stylus" scoped src="./Conecta.styl"></style>
<style lang="stylus">
    @keyframes boxAnimation
        100%
            opacity 1; transform translateY(0)
</style>
