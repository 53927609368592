<template lang="pug">
    section#servicos
        .wrapper
            ul.areas
                li(v-for="area, index in areas" :class="`_0${index+1}`").area
                    .icon
                        img(:src="area.icon")
                    .text
                        h3(v-html="area.title")
                        ul.services
                            li(v-for="service, subIndex in area.services" :class="`_0${subIndex+1}`").service
                                h4 {{service.title}}
                                p(v-html="service.text")
                                ul(v-if="service.list")
                                    li(v-for="listItem in service.list") {{ listItem }}
</template>

<script>
export default {
    name: "section-servicos",
    data() {
        return {
            areas: [
                {
                    title: "R<span class='_1100'>E</span>S<span class='_1100'>EA</span>RCH Y<span class='_1100'>OU</span>R N<span class='_1100'>EU</span>R<span class='_1100'>A</span>L F<span class='_1100'>U</span>T<span class='_1100'>U</span>R<span class='_1100'>E</span>",
                    icon: require('@images/icons/research.png'),
                    services: [
                        // {
                        //     title: "Pesquisa e Análise de Tendências / Coolhunting",
                        //     text: "Exploramos padrões socioculturais do segmento em que seu negócio está inserido, identificamos o público-alvo e comportamentos de consumo dos usuários, para mapear tendências e desenhar possíveis cenários futuros. Para isso, utilizamos instrumentos como: pesquisas de abordagem etnográfica, monitoramento de tendências, entrevistas em profundidade, grupos focais, análises de big data, coleta iconográfica, coleta bibliográfica, definições de macrotendências, construção de metacenários e de visão futura."
                        // },
                        // {
                        //     title: "Consultoria de Inovação",
                        //     text: "Assessoramos projetos de inovação com a busca de parceiros especializados e específicos. Construímos processos passíveis de controle em todas as etapas e coordenamos os testes para a sua adequação, tendo em vista a minimização de riscos na inovação. Essa consultoria também pode contemplar workshops de co-criação em idea labs."
                        // },
                        // {
                        //     title: "Diagnóstico Estratégico de Negócio / Pesquisa",
                        //     text: "Reconhecemos as novas tendências no mercado para traçar o melhor caminho para que seu negócio entre ou se diferencie no mundo digital. Co-criamos com você os impulsos necessários para desenvolvimento de processos e fluxos e verificamos novos produtos e serviços que auxiliem na completa transformação digital."
                        // },
                        {
                            title: "Consultoria em Transformação Digital",
                            text: "Visando identificar novas oportunidades para o negócio, exploramos padrões socioculturais e comportamentais, analisamos a estratégia empresarial e o cenário no qual está inserido. Também avaliamos a visão dos stakeholders e mapeamos macro e microtendências.<br/>Para isso, utilizamos alguns métodos e ferramentas, tais como:",
                            list: [
                                "Pesquisa e análise de tendência/coolhunting",
                                "Pesquisa contextual e de mercado",
                                "Entrevistas em profundidade",
                                "Pesquisa de abordagem etnográfica",
                                "Business Analytics",
                            ]
                        },
                    ]
                },
                {
                    title: "B<span class='_1100'>O</span>OS<span class='_1100'>T</span> YO<span class='_1100'>u</span>r<br> b<span class='_1100'>u</span><span class='_1300'>s</span>iN<span class='_1100'>E</span>ss & M<span class='_1100'>I</span>NDs<span class='_1900'>e</span>t",
                    icon: require('@images/icons/businessboost.png'),
                    services: [
                        // {
                        //     title: "Business Design",
                        //     text: "Visando a sustentabilidade do business e sempre com o foco no cliente, identificamos desafios e oportunidades para o desenvolvimento de um novo modelo de negócio que gere valor. Para isso, por meio de diversas técnicas de pesquisas, compreendemos o estado atual da empresa, evidenciamos o propósito, os concorrentes e os players do mercado. Juntamos todas essas informações com as ferramentas mais atuais do Design Prospectivo e de Negócios para tangibilizarmos as ideias, visando colocar o desenho do novo modelo de negócio em prática."
                        // },
                        // {
                        //     title: "Gestão Estratégica de Marca / Branding",
                        //     text: "Desenvolvemos estratégias para agregar valor a sua marca em todos os pontos de contato com os stakeholders. O objetivo é criar um plano de gestão que possa garantir que as interações sejam positivas e que comuniquem o propósito da sua organização. A Gestão Estratégica de Marca pode contemplar: arquitetura de marca, criação de propósito e valor, análise de performance, construção de personas, logotipo/identidade visual e verbal e plano e consultoria de implantação de branding. "
                        // },
                        {
                            title: "Jornada e Experiência do Consumidor",
                            text: "Ao analisar em profundidade os usuários e suas interações com a marca, ao longo de diferentes pontos de contato, nós desenhamos e aprimoramos jornadas, viabilizando meios para que os consumidores tenham uma experiência impecável. Para isso, utilizamos:",
                            list: [
                                "Análise e diagnóstico da experiência atual do usuário",
                                "Identificação de pontos de dores, barreiras, frustrações, motivações e oportunidades",
                                "Mapeamento da nova jornada",
                            ]
                        },
                        {
                            title: "Service Design",
                            text: "Por meio dos projetos de design de serviços, delineamos as condições necessárias para que as pessoas tenham uma experiência positiva e relevante com o serviço ofertado. Primeiramente, com o olhar centrado no usuário, coletamos e analisamos dados. Em seguida, geramos conceitos, criamos e testamos protótipos. E, por fim, desenhamos um mapa com o passo a passo do serviço. Para concretizar isso, podemos utilizar:",
                            list: [
                                "Método sombra, grupo focal, entrevista em profundidade",
                                "Criação de personas, storyboard, diagrama de polaridade",
                                "Prototipação de cenários",
                                "Mapa de sistema",
                                "Blueprint"
                            ]
                        },
                        {
                            title: "Melhoria de Processo",
                            text: "Com uma visão sistêmica, mergulhamos em profundidade nos processos internos e em seu negócio para delinearmos a situação atual e identificarmos pontos sensíveis, de modo a definir e aprimorar procedimentos e redesenhar processos. Para esse propósito, utilizamos métodos e abordagens como:",
                            list: [
                                "Linguagem BPMN.",
                                "Value Stream Mapping e reengenharia de processos",
                                "Ciclos PDCA e DMAIC",
                                "Diagrama de Pareto, 5 Porquês, Ishikawa e Fault Tree Analysis",
                            ]
                        },
                    ]
                },
                {
                    title: "r<span class='_1900'>e</span>M<span class='_1100'>I</span>ND & <span class='_1100'>I</span>mpr<span class='_1100'>o</span>v<span class='_1100'>e</span> <br> y<span class='_1100'>ou</span>r b<span class='_1100'>r</span>a<span class='_1100'>n</span>d",
                    icon: require('@images/icons/remind.png'),
                    services: [
                        // {
                        //     title: "Jornada e Experiência do Consumidor / Design de Serviços",
                        //     text: "Analisamos em profundidade os usuários e os pontos de contato com a marca para que possamos desenhar e aprimorar jornadas, possibilitando uma experiência impecável ao consumidor. Para isso, entrevistamos stakeholders, geramos conceitos, criamos e testamos protótipos e desenhamos um mapa com o passo a passo do serviço. Além disso, servitizamos produtos de modo a criar sistemas de Produto + Serviço por meio dos quais os clientes pagam principalmente para ter acesso a serviços e não mais a produtos."
                        // },
                        // {
                        //     title: "Melhoria de Processos",
                        //     text: "Aceleramos seu negócio criando soluções adaptadas aos desafios do mercado, trazendo inovação e eficiência ao seu projeto. Com uma visão sistêmica, nós mergulhamos fundo nos processos internos e em seu negócio, de modo a definir e aprimorar procedimentos. Mapeamos a situação atual, identificamos pontos sensíveis e redesenhamos processos, o que incide em maior velocidade, praticidade e menores custos."
                        // },
                        {
                            title: "Estratégia e Gestão de Marca",
                            text: "Um negócio e uma marca são feitos de interações entre pessoas, processos, entregas e experiências. Sendo assim, desenvolvemos estratégias para agregar valor à sua marca em todos os pontos de contato com os stakeholders. O objetivo é criar um plano de gestão de marca que possa garantir que as interações sejam positivas e que comuniquem o propósito da organização. Entre o que contempla esse serviço, temos:",
                            list: [
                                "Arquitetura de marca",
                                "Definição de propósito, valores e pilares estratégicos",
                                "Construção de personas, logotipo/identidade visual e verbal",
                                "Análise de performance",
                                "Plano e consultoria para implantação de branding",
                            ]
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style lang="stylus" scoped src="./Servicos.styl"></style>
