import { render, staticRenderFns } from "./QuemSomos.vue?vue&type=template&id=58dcbf8a&scoped=true&lang=pug&"
import script from "./QuemSomos.vue?vue&type=script&lang=js&"
export * from "./QuemSomos.vue?vue&type=script&lang=js&"
import style0 from "./QuemSomos.styl?vue&type=style&index=0&id=58dcbf8a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58dcbf8a",
  null
  
)

export default component.exports