<template lang="pug">
	main#servicos
		Header
		Banner
		QuemSomos
		Servicos
		Conecta
</template>

<script>
import Header from '@components/Header/Header'
import Servicos from '@sections/Servicos/Servicos/Servicos'
import QuemSomos from '@sections/Servicos/QuemSomos/QuemSomos'
import Banner from '@sections/Servicos/Banner/Banner'
import Conecta from '@sections/Servicos/Conecta/Conecta'

import Scenes from './scenes.js'

export default {
	name: "view-servicos",
	components: {
		Header,
		Banner,
		QuemSomos,
		Servicos,
		Conecta,
	},
	mixins: [
		Scenes,
	],
	metaInfo() {
		return {
			title: 'Serviços',
			meta: [
				{ name: 'description', content: 'Seu desafio no centro do nosso córtex' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Servicos.styl"></style>